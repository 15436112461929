<template>
  <div class="table_actions">
    <call-tooltip
      placement="top"
      content="Info">
      <call-button
        outlet
        type="success"
        round
        not-border
        :icon="dataViewIcon"
        @click="clickAction('view')" />
    </call-tooltip>
    <call-tooltip
      v-if="computedIsAdmin"
      placement="top"
      content="Edit">
      <call-button
        outlet
        type="warning"
        round
        not-border
        :icon="dataReadingIcon"
        @click="clickAction('update')" />
    </call-tooltip>
    <call-tooltip
      v-if="((computedIsAdmin) && computedCurrentUser.id !== userId)"
      placement="top"
      :content="!block ? 'Block' : 'Unblock'">
      <call-button
        outlet
        :type="!block ? 'danger' : 'success'"
        round
        not-border
        :icon="!block ? dataLockIcon : dataLockOpenIcon"
        @click="clickAction('blocked')" />
    </call-tooltip>
  </div>
</template>

<script>
import CallButton from '../common/CallButton';
import { faEye, faLock, faLockOpen, faPen } from '@fortawesome/free-solid-svg-icons';
import { mapGetters } from 'vuex';

export default {
  name: 'CallTableActions',
  components: { CallButton },
  props: {
    block: {
      type: Boolean,
      default: false
    },
    userId: {
      type: [String, Number],
      default: null
    }
  },
  data () {
    return {
      dataViewIcon: faEye,
      dataReadingIcon: faPen,
      dataLockIcon: faLock,
      dataLockOpenIcon: faLockOpen
    };
  },
  computed: {
    ...mapGetters({
      computedIsAdmin: 'auth/isAdmin',
      computedIsModerator: 'auth/isModerator',
      computedCurrentUser: 'auth/currentUser'
    })
  },
  methods: {
    clickAction (action) {
      this.$emit('on-action', action);
    }
  }
};
</script>
