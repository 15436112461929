<template>
  <b-modal
    v-model="dataModal"
    centered
    no-close-on-backdrop
    no-close-on-esc
    title="Update User"
    @close="onCloseModal">
    <div>
      <call-input
        v-model="$v.dataForm.name.$model"
        type-input="text"
        :valide="$v.dataForm.name.$error"
        float-name-label="Name" />
      <call-input
        v-model="$v.dataForm.email.$model"
        :valide="$v.dataForm.email.$error"
        type-input="text"
        float-name-label="Email" />
      <div class="password__wrapper">
        <call-input
          v-model.trim="dataForm.password"
          type-input="text"
          type="password"
          float-name-label="Password"
          :show-password="dataShowPassword" />
        <call-button
          :icon="dataIconRefresh"
          type="icon-only"
          class="call-button--outlet"
          @click="clickGeneratePassword()" />
      </div>
      <call-input
        v-show="computedIsOperator"
        v-model="$v.dataForm.phone.$model"
        type-input="text"
        type="number"
        :valide="$v.dataForm.phone.$error"
        float-name-label="Phone" />
      <call-input
        v-show="computedIsOperator"
        v-model="dataForm.zoiper_id"
        type-input="text"
        float-name-label="Zoiper ID" />
      <div
        v-show="computedIsOperator"
        :class="['call-select-modal__wrapper', 'call-input',
                 !dataForm.team_id ? 'call-select--empty' : null,
                 $v.dataForm.team_id.$error ? 'error-valide' : null]">
        <v-select
          v-model="$v.dataForm.team_id.$model"
          :options="dataSelectOptionsTeam"
          :components="{Deselect: dataComponentDeselect}"
          label="name"
          placeholder="Team"
          class="call-select-modal call-select">
          <template #open-indicator="{ attributes }">
            <span v-bind="attributes">
              <font-awesome-icon :icon="dataIconDown" />
            </span>
          </template>
          <template slot="no-options">
            Введите имя оффиса
          </template>
          <template
            slot="option"
            slot-scope="option">
            <div class="d-center">
              {{ option.name }}
            </div>
          </template>
        </v-select>
        <label>Team</label>
      </div>
    </div>
    <template v-slot:modal-footer>
      <call-button
        :icon="dataCloseIcon"
        type="danger"
        plain
        @click="onCloseModal()">
        Cancel
      </call-button>
      <call-button
        :icon="dataRefreshIcon"
        :disabled="$v.$invalid"
        type="success"
        @click="clickUpdateUser()">
        Update user
      </call-button>
    </template>
  </b-modal>
</template>

<script>
import { BModal } from 'bootstrap-vue';
import { faSearch, faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import CallButton from '../CallButton';
import CallInput from '../CallInput';
import { email, minLength, required, requiredIf } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import { ID_ROLE_OPERATOR, ID_ROLE_TEAM_LEADER } from '../../../service/consts';
import { TEAMS_GET_ALL, USERS_GET, USERS_UPDATE } from '../../../store/types/actions.types';
import iconsModal from '../../../mixins/iconsModal';
import selectAllTeams from '../../../mixins/select/selectAllTeams';

const secureMathRandom = () => {
  return window.crypto.getRandomValues(new Uint32Array(1))[0] / (Math.pow(2, 32) - 1);
};

const getRandomLower = () => {
  return String.fromCharCode(Math.floor(Math.random() * 26) + 97);
};
const getRandomUpper = () => {
  return String.fromCharCode(Math.floor(Math.random() * 26) + 65);
};
const getRandomNumber = () => {
  return String.fromCharCode(Math.floor(secureMathRandom() * 10) + 48);
};

const getRandomSymbol = () => {
  const symbols = '~!@#$%^&*()_+{}":?><;.,';
  return symbols[Math.floor(Math.random() * symbols.length)];
};

const randomFunc = {
  lower: getRandomLower,
  upper: getRandomUpper,
  number: getRandomNumber,
  symbol: getRandomSymbol
};

export default {
  name: 'CallModalUpdateUser',
  components: {
    CallInput,
    CallButton,
    BModal
  },
  mixins: [validationMixin, iconsModal, selectAllTeams],
  props: {
    value: {
      type: Boolean,
      default: false
    },
    userId: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      dataModal: this.value,
      dataIconSearch: faSearch,
      dataIconRefresh: faSyncAlt,
      dataShowPassword: false,
      dataForm: {
        name: null,
        email: null,
        password: null,
        role: ID_ROLE_OPERATOR,
        team_id: null,
        phone: null,
        zoiper_id: null
      }
    };
  },
  validations: {
    dataForm: {
      name: {
        required
      },
      phone: {
        minLength: minLength(6),
        required: requiredIf(function () {
          return this.dataForm.role === ID_ROLE_OPERATOR;
        })
      },
      email: {
        required,
        email
      },
      team_id: {
        required: requiredIf(function () {
          return this.dataForm.role === ID_ROLE_OPERATOR;
        })
      }
    }
  },
  computed: {
    computedIsOperator () {
      return this.dataForm.role === ID_ROLE_OPERATOR;
    }
  },
  watch: {
    async value () {
      this.dataModal = this.value;
      if (this.dataModal) {
        Object.assign(this.$data, this.$options.data.apply(this));
        this.$v.$reset();

        const [{ payload: { list = [] } }, { user }] = await Promise.all([
          this.$store.dispatch(`team/${TEAMS_GET_ALL}`, {
            params: {
              short: true
            }
          }),
          this.$store.dispatch(`user/${USERS_GET}`, {
            params: {
              id: this.userId
            }
          })
        ]);
        this.dataSelectOptionsTeam = list;
        this.$set(this.dataForm, 'name', user?.name);
        this.$set(this.dataForm, 'email', user?.email);
        this.$set(this.dataForm, 'role', user?.role);
        this.$set(this.dataForm, 'team_id', user?.teams);
        this.$set(this.dataForm, 'phone', user?.phone);
        this.$set(this.dataForm, 'zoiper_id', user?.zoiper_id);
      }
    }
  },
  methods: {
    clickGeneratePassword (length = 8, lower = true, upper = true, number = true, symbol = false) {
      let generatedPassword = '';
      const typesCount = lower + upper + number + symbol;
      const typesArr = [{ lower }, { upper }, { number }, { symbol }].filter(item => Object.values(item)[0]);
      if (typesCount === 0) {
        return '';
      }
      for (let i = 0; i < length; i++) {
        typesArr.forEach(type => {
          const funcName = Object.keys(type)[0];
          generatedPassword += randomFunc[funcName]();
        });
      }
      this.dataShowPassword = true;
      this.$set(this.dataForm, 'password', generatedPassword.slice(0, length));
    },
    onCloseModal () {
      this.$emit('input', false);
    },
    async clickUpdateUser () {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return false;
      }
      try {
        await this.$store.dispatch(`user/${USERS_UPDATE}`, {
          userId: this.userId,
          params: {
            ...this.dataForm,
            team_id: ID_ROLE_TEAM_LEADER === this.dataForm.role ? null : this.dataForm.team_id?.id,
            role: null,
            zoiper_id: this.computedIsOperator ? this.dataForm.zoiper_id : null
          },
          context: this
        });
        this.$emit('on-update');
        this.$Notify({
          title: 'User',
          message: 'Successfully updated',
          type: 'success'
        });
        this.onCloseModal();
      } catch (e) {
        throw Error(e);
      }
    }
  }
};
</script>
